// =============================================================================
// STORES PAGES LAYOUTS
// =============================================================================

export default {
  path: '/warehouses',
  component: () => import('../../views/admin/warehouses/warehousesList/index'),
  children: [
  {
    path: '',
    name: 'warehousesList',
    component: () => import(/* webpackChunkName: "warehousesList" */ '../../views/admin/warehouses/warehousesList/list/warehousesList.vue'),
    meta: {
      permission: 'storeroom.show',
      pageTitle: '',
      pageTitleI18n: 'warehousing.stores.list.title'
      // rule: 'editor'
    }
  },
  {
    path: 'trash',
    name: 'warehousesTrash',
    component: () => import(/* webpackChunkName: "warehousesTrash" */ '../../views/admin/warehouses/warehousesList/trash/warehousesTrash.vue'),
    meta: {
      permission: ['storeroom.show', 'storeroom.forceDelete', 'storeroom.restore'],
      pageTitle: '',
      pageTitleI18n: 'warehousing.stores.trash.title'
      // rule: 'editor'
    }
  },
  {
    path: 'str-:id',
    component: () => import('../../views/admin/warehouses/warehousesList/warehouse/index'),
    children: [
      {
        path: '/',
        name: 'warehouse',
        component: () => import(/* webpackChunkName: "store" */ '../../views/admin/warehouses/warehousesList/warehouse/menu/warehouseMenu.vue'),
        meta: {
          permission: 'storeroom.show',
          pageTitle: '',
          pageTitleI18n: 'warehousing.stores.store.title',
          layoutStatus: 'profile'
          // rule: 'editor'
        }
      },
      {
        path: 'edit',
        name: 'editWarehouse',
        component: () => import(/* webpackChunkName: "editStore" */ '../../views/admin/warehouses/warehousesList/warehouse/edit/editWarehouse.vue'),
        meta: {
          permission: 'storeroom.show',
          pageTitle: '',
          pageTitleI18n: 'warehousing.stores.edit.title',
          layoutStatus: 'profile'
          // rule: 'editor'
        }
      },
      {
        path: 'transactions',
        name: 'warehouseTransactions',
        component: () => import(/* webpackChunkName: "warehouseTransactions" */ '../../views/admin/warehouses/warehousesList/warehouse/transactions/warehouseTransactions.vue'),
        meta: {
          permission: 'storeroom.show',
          pageTitle: '',
          pageTitleI18n: 'warehousing.turnover.title',
          layoutStatus: 'profile'
          // rule: 'editor'
        }
      },
      {
        path: 'turnover',
        name: 'warehouseTurnover',
        component: () => import(/* webpackChunkName: "warehouseTurnover" */ '../../views/admin/warehouses/warehousesList/warehouse/turnover/warehouseTurnover.vue'),
        meta: {
          permission: 'storeroom.turnover',
          pageTitle: '',
          pageTitleI18n: 'warehousing.turnover.title',
          layoutStatus: 'profile'
          // rule: 'editor'
        }
      },
      {
        path: 'inventory',
        name: 'warehouseInventory',
        component: () => import(/* webpackChunkName: "warehouseInventory" */ '../../views/admin/warehouses/inventory/warehousesInventory.vue'),
        meta: {
          permission: 'storeroom.show',
          pageTitle: '',
          pageTitleI18n: 'warehousing.inventory.title',
          layoutStatus: 'profile'
          // rule: 'editor'
        }
      },
      {
        path: 'inventory-valuation',
        name: 'warehouseInventoryValuation',
        component: () => import(/* webpackChunkName: "warehouseInventoryValuation" */ '../../views/admin/warehouses/warehousesList/warehouse/inventory/warehousesInventory.vue'),
        meta: {
          permission: 'storeroom.show',
          pageTitle: '',
          pageTitleI18n: 'warehousing.inventoryValuation.title',
          layoutStatus: 'profile'
          // rule: 'editor'
        }
      },
      {
        path: 'activities-log',
        name: 'warehouseActivitiesLog',
        component: () => import(/* webpackChunkName: "warehouseActivitiesLog" */ '../../views/admin/warehouses/warehousesList/warehouse/activitiesLog/warehouseActivitiesLog.vue'),
        meta: {
          permission: 'activity_logs.show',
          pageTitle: 'لاگ های انبار',
          pageTitleI18n: 'warehousing.logs.title',
          layoutStatus: 'profile'
          // rule: 'editor'
        }
      }
    ]
  },
  {
    path: 'insert',
    name: 'InsertWarehouse',
    component: () => import(/* webpackChunkName: "InsertWarehouse" */ '../../views/admin/warehouses/warehousesList/insert/insertWarehouse.vue'),
    meta: {
      permission: 'storeroom.create',
      pageTitleI18n: 'warehousing.stores.insert.title'
      // rule: 'editor'
    }
  },
  {
    path: 'inventory',
    name: 'warehousesInventory',
    component: () => import(/* webpackChunkName: "warehousesInventory" */ '../../views/admin/warehouses/inventory/warehousesInventory.vue'),
    meta: {
      permission: 'storeroom.inventory',
      pageTitle: '',
      pageTitleI18n: 'warehousing.inventory.title'
      // rule: 'editor'
    }
  },
  {
    path: 'turnover',
    name: 'warehousesTurnover',
    component: () => import(/* webpackChunkName: "warehousesTurnover" */ '../../views/admin/warehouses/turnover/warehousesTurnover.vue'),
    meta: {
      permission: 'storeroom.turnover',
      pageTitle: '',
      pageTitleI18n: 'warehousing.turnover.title'
      // rule: 'editor'
    }
  },
  {
    path: 'inventory-valuation',
    name: 'warehousesInventoryValuation',
    component: () => import(/* webpackChunkName: "warehousesInventoryValuation" */ '../../views/admin/warehouses/inventory/warehousesInventory.vue'),
    meta: {
      permission: 'storeroom.inventoryValuation',
      pageTitle: '',
      pageTitleI18n: 'warehousing.inventoryValuation.title'
      // rule: 'editor'
    }
  },
  {
    path: 'receipts',
    component: () => import(/* webpackChunkName: "receipts" */ '../../views/admin/warehouses/receipts/index'),
    children: [
      {
        path: '',
        name: 'warehouseReceipts',
        component: () => import(/* webpackChunkName: "warehouseReceipts" */ '../../views/admin/warehouses/receipts/list/warehouseReceiptsList.vue'),
        meta: {
          permission: 'storeroom_receipt.show',
          pageTitle: '',
          pageTitleI18n: 'warehousing.receipts.list.title'
          // rule: 'editor'
        }
      },
      {
        path: 'insert',
        name: 'insertWarehouseReceipt',
        component: () => import(/* webpackChunkName: "insertWarehouseReceipt" */ '../../views/admin/warehouses/receipts/insert/insertWarehouseReceipt.vue'),
        meta: {
          permission: 'storeroom_receipt.create',
          pageTitle: '',
          pageTitleI18n: 'warehousing.receipts.insert.title'
          // rule: 'editor'
        }
      },
      {
        path: ':id',
        component: () => import('../../views/admin/warehouses/receipts/receipt/index'),
        children: [
          {
            path: '/',
            name: 'warehouseReceipt',
            component: () => import(/* webpackChunkName: "warehouseReceipt" */ '../../views/admin/warehouses/receipts/receipt/warehouseReceipt.vue'),
            meta: {
              permission: 'storeroom_receipt.show',
              pageTitle: '',
              pageTitleI18n: 'warehousing.receipts.receipt.title'
              // rule: 'editor'
            }
          },
          {
            path: 'edit',
            name: 'editWarehouseReceipt',
            component: () => import(/* webpackChunkName: "editWarehouseReceipt" */ '../../views/admin/warehouses/receipts/edit/editWarehouseReceipt.vue'),
            meta: {
              permission: 'activity_logs.show',
              pageTitle: 'ویرایش حواله انبار',
              pageTitleI18n: 'warehousing.receipts.edit.title'
              // rule: 'editor'
            }
          },
          {
            path: 'activities-log',
            name: 'warehouseReceiptActivitiesLog',
            component: () => import(/* webpackChunkName: "warehouseReceiptActivitiesLog" */ '../../views/admin/warehouses/receipts/receipt/activitiesLog/warehouseReceiptActivitiesLog.vue'),
            meta: {
              permission: 'activity_logs.show',
              pageTitle: 'لاگ های حواله انبار',
              pageTitleI18n: 'warehousing.receipts.receipt.logs.title'
              // rule: 'editor'
            }
          },
        ]
      }
    ]
  },
  {
    path: 'deficits',
    component: () => import(/* webpackChunkName: "deficit" */ '../../views/admin/warehouses/deficits/index'),
    children: [
      {
        path: '',
        name: 'warehouseDeficitsList',
        component: () => import(/* webpackChunkName: "warehouseDeficitsList" */ '../../views/admin/warehouses/deficits/list/warehouseDeficitsList.vue'),
        meta: {
          permission: 'storeroom_deficit.show',
          pageTitle: '',
          pageTitleI18n: 'warehousing.deficit.list.title'
          // rule: 'editor'
        }
      },
      {
        path: 'trash',
        name: 'warehouseDeficitsTrash',
        component: () => import(/* webpackChunkName: "warehouseDeficitsTrash" */ '../../views/admin/warehouses/deficits/trash/warehouseDeficitsTrash.vue'),
        meta: {
          permission: ['storeroom_deficit.show', 'storeroom_deficit.forceDelete', 'storeroom_deficit.restore'],
          pageTitle: '',
          pageTitleI18n: 'warehousing.deficit.trash.title'
          // rule: 'editor'
        }
      },
      {
        path: 'trash/:id',
        name: 'warehouseDeficitTrashProfile',
        component: () => import(/* webpackChunkName: "warehouseDeficitTrashProfile" */ '../../views/admin/warehouses/deficits/profile/warehouseDeficitProfile.vue'),
        meta: {
          permission: 'storeroom_deficit.show',
          pageTitle: '',
          pageTitleI18n: 'warehousing.deficit.profile.title'
          // rule: 'editor'
        }
      },
      {
        path: 'insert',
        name: 'insertWarehouseDeficit',
        component: () => import(/* webpackChunkName: "insertWarehouseDeficit" */ '../../views/admin/warehouses/deficits/insert/insertWarehouseDeficit.vue'),
        meta: {
          permission: 'storeroom_deficit.create',
          pageTitle: '',
          pageTitleI18n: 'warehousing.deficit.insert.title'
          // rule: 'editor'
        }
      },
      {
        path: ':id',
        component: () => import('../../views/admin/warehouses/deficits/index'),
        children: [
          {
            path: '/',
            name: 'warehouseDeficit',
            component: () => import(/* webpackChunkName: "warehouseDeficit" */ '../../views/admin/warehouses/deficits/profile/warehouseDeficitProfile.vue'),
            meta: {
              permission: 'storeroom_deficit.show',
              pageTitle: '',
              pageTitleI18n: 'warehousing.deficit.profile.title'
              // rule: 'editor'
            }
          },
          {
            path: 'edit',
            name: 'editWarehouseDeficit',
            component: () => import(/* webpackChunkName: "editWarehouseDeficit" */ '../../views/admin/warehouses/deficits/edit/editWarehouseDeficit.vue'),
            meta: {
              permission: 'storeroom_deficit.update',
              pageTitle: '',
              pageTitleI18n: 'warehousing.deficit.edit.title'
              // rule: 'editor'
            }
          },
          {
            path: 'activities-log',
            name: 'warehouseDeficitActivitiesLog',
            component: () => import(/* webpackChunkName: "warehouseDeficitActivitiesLog" */ '../../views/admin/warehouses/deficits/activitiesLog/warehouseDeficitActivitiesLog.vue'),
            meta: {
              permission: 'activity_logs.show',
              pageTitle: 'لاگ های حواله انبار',
              pageTitleI18n: 'warehousing.deficit.logs.title'
              // rule: 'editor'
            }
          }
        ]
      }
    ]
  },
  {
    path: 'conversions',
    component: () => import(/* webpackChunkName: "conversions" */ '../../views/admin/warehouses/conversions/index'),
    children: [
      {
        path: '',
        name: 'warehouseConversionsList',
        component: () => import(/* webpackChunkName: "warehouseConversionsList" */ '../../views/admin/warehouses/conversions/list/warehouseConversionsList.vue'),
        meta: {
          permission: 'storeroom_conversion.show',
          pageTitle: '',
          pageTitleI18n: 'warehousing.conversion.list.title'
          // rule: 'editor'
        }
      },
      {
        path: 'trash',
        name: 'warehouseConversionsTrash',
        component: () => import(/* webpackChunkName: "warehouseConversionsTrash" */ '../../views/admin/warehouses/conversions/trash/warehouseConversionsTrash.vue'),
        meta: {
          permission: ['storeroom_conversion.show', 'storeroom_conversion.forceDelete', 'storeroom_conversion.restore'],
          pageTitle: '',
          pageTitleI18n: 'warehousing.conversion.trash.title'
          // rule: 'editor'
        }
      },
      {
        path: 'trash/:id',
        name: 'warehouseConversionTrashProfile',
        component: () => import(/* webpackChunkName: "warehouseConversionTrashProfile" */ '../../views/admin/warehouses/conversions/profile/warehouseConversionsProfile.vue'),
        meta: {
          permission: 'storeroom_conversion.show',
          pageTitle: '',
          pageTitleI18n: 'warehousing.conversion.profile.title'
          // rule: 'editor'
        }
      },
      {
        path: 'insert',
        name: 'insertWarehouseConversion',
        component: () => import(/* webpackChunkName: "insertWarehouseConversion" */ '../../views/admin/warehouses/conversions/insert/insertWarehouseConversions.vue'),
        meta: {
          permission: 'storeroom_conversion.create',
          pageTitle: '',
          pageTitleI18n: 'warehousing.conversion.insert.title'
          // rule: 'editor'
        }
      },
      {
        path: ':id',
        component: () => import('../../views/admin/warehouses/conversions/index'),
        children: [
          {
            path: '/',
            name: 'warehouseConversion',
            component: () => import(/* webpackChunkName: "warehouseConversion" */ '../../views/admin/warehouses/conversions/profile/warehouseConversionsProfile.vue'),
            meta: {
              permission: 'storeroom_conversion.show',
              pageTitle: '',
              pageTitleI18n: 'warehousing.conversion.profile.title'
              // rule: 'editor'
            }
          },
          {
            path: 'edit',
            name: 'editWarehouseConversion',
            component: () => import(/* webpackChunkName: "editWarehouseConversion" */ '../../views/admin/warehouses/conversions/edit/editWarehouseConversions.vue'),
            meta: {
              permission: 'storeroom_conversion.update',
              pageTitle: '',
              pageTitleI18n: 'warehousing.conversion.edit.title'
              // rule: 'editor'
            }
          },
          {
            path: 'activities-log',
            name: 'warehouseConversionActivitiesLog',
            component: () => import(/* webpackChunkName: "warehouseConversionActivitiesLog" */ '../../views/admin/warehouses/conversions/activitiesLog/warehouseConversionsActivitiesLog.vue'),
            meta: {
              permission: 'activity_logs.show',
              pageTitle: 'لاگ های حواله انبار',
              pageTitleI18n: 'warehousing.conversion.logs.title'
              // rule: 'editor'
            }
          }
        ]
      }
    ]
  }
]
}
