<template>
  <div class="edit-products-opening-balance">

    <div class="edit-products-opening-balance-box">

      <vs-row class="my-2">
        <vs-col class="w-full">
          <custom-number-input class="w-full"
                               v-model="updatedProduct.count"
                               :autocomplete="false"
                               :name="Math.random()"
                               :label="$t('products.openingBalance.labels.count')"/>
        </vs-col>
      </vs-row>

      <vs-row class="my-2">
        <vs-col class="w-full">
          <custom-price-input class="w-full"
                              :label="$t('products.openingBalance.labels.price')"
                              :autocomplete="false"
                              :name="Math.random()"
                              v-model="updatedProduct.unitPrice"/>
        </vs-col>
      </vs-row>

      <vs-row class="my-2">
        <vs-col class="w-full">
          <vs-button class="w-full"
                     color="danger"
                     @click="$refs.deleteOpeningBalanceConfirmation.showDialog()">
            {{ $t('products.openingBalance.labels.delete') }}
          </vs-button>
        </vs-col>
      </vs-row>
    </div>

    <custom-dialog ref="deleteOpeningBalanceConfirmation"
                   :title="$t('products.openingBalance.confirmations.delete.title')"
                   :body="$t('products.openingBalance.confirmations.delete.body')"
                   @accept="handleDeleteProductOpeningBalance"/>

    <button v-show="false" id="saveBTN" @click="sendData"/>
  </div>
</template>

<script>
import axios from 'axios'
import SelectProducts from '@/views/admin/products/productsList/select/selectProducts'
import InsertProduct from '@/views/admin/products/productsList/insert/insertProduct'
import CustomIcon from '../../../../../components/customIcon/customIcon'
import {
  deleteStoreroomsOpeningInventories, updateStoreroomsOpeningInventories
} from '../../../../../http/requests/openingInventories'
import CustomDialog from '../../../../../components/customDialog/customDialog'
import CustomNumberInput from "../../../../../components/customInput/customNumberInput.vue";
import CustomPriceInput from "../../../../../components/customInput/customPriceInput.vue";

export default {
  name: 'editProductsOpeningBalance',
  components: {CustomPriceInput, CustomNumberInput, CustomDialog, CustomIcon, InsertProduct, SelectProducts},
  props: {
    storeroomId: {
      type: [Number, String],
      default: 0,
      validator (value) {
        return parseInt(value || 0) >= 0
      }
    },
    product: {
      type: Object,
      default: () => { return {} }
    }
  },
  data () {
    return {
      updatedProduct: {}
    }
  },
  created () {
    this.updatedProduct = JSON.parse(JSON.stringify(this.product))
    this.updatedProduct.count = {
      value: this.product.count,
      isValid: true
    }
    this.updatedProduct.unitPrice = {
      value: this.product.unitPrice,
      isValid: true
    }
  },
  methods: {
    sendData () {
      const product = {
        quantity: parseInt(this.updatedProduct.count.value.toString().replaceAll(',', '')),
        unit_price: this.$u.parseFloat(this.updatedProduct.unitPrice.value.toString().replaceAll(',', ''))
      }
      updateStoreroomsOpeningInventories(this.storeroomId, this.updatedProduct.id, product).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('products.openingBalance.notifications.edit.success'),
          color: 'success',
          time: this.$u.getReadingTime(this.$t('products.openingBalance.notifications.edit.success')),
          icon: 'icon-check',
          iconPack: 'feather'
        })

        this.$store.dispatch('helper/changeOpeningBalance')
      }).catch((error) => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: this.$u.getReadingTime(this.$t('alert.duplicateRequest.message')),
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('products.openingBalance.notifications.edit.error'),
            color: 'danger',
            time: this.$u.getReadingTime(this.$t('products.openingBalance.notifications.edit.error')),
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
        }
      })
    },
    handleDeleteProductOpeningBalance () {
      if (this.product && this.product.id) {
        deleteStoreroomsOpeningInventories(this.storeroomId, this.product.id).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('products.openingBalance.notifications.delete.success'),
            color: 'success',
            time: this.$u.getReadingTime(this.$t('products.openingBalance.notifications.delete.success')),
            icon: 'icon-check',
            iconPack: 'feather'
          })

          this.$store.dispatch('helper/deleteOpeningBalance')
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: this.$u.getReadingTime(this.$t('alert.duplicateRequest.message')),
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('products.openingBalance.notifications.delete.error'),
              color: 'danger',
              time: this.$u.getReadingTime(this.$t('products.openingBalance.notifications.delete.error')),
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
.select-file-input {
  width: 100%;
  background: #28c76f;
  display: block;
  padding: 10px;
  border-radius: .5rem;

  span {
    color: white !important;

    div.icon {
      background-color: white !important;
    }
  }

  input {
    display: none;
  }
}

.products-opening-balance {
  height: 100%;
}
</style>
