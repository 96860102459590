<template>
  <div class="access-group-users">
    <draggable-dynamic-table ref="usersListTable"
                             :columns="columnsLabel"
                             :options="options"
                             :in-modal="true"
                             @filter:remove="setFilters($event)"
                             @filter:set="setFilters($event)"
                             @sort:set="setSort($event)"
                             @load:more="getUsers()"
                             @row:clicked="showUser"
                             @user:deleted="$refs.deleteConfirmation.showDialog(), selectedUser = $event"
                             v-model="data"/>

    <!-- show user details prompt -->
    <vs-prompt
      class="medium-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showUserPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('users.profile.title') }} {{selectedUser.name}}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showUserPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <user-profile :user-id="selectedUser.id"/>
          </keep-alive>
        </template>
      </div>
    </vs-prompt>

    <custom-dialog ref="deleteConfirmation"
                   :title="`${$t('setting.accessGroup.confirmations.deleteUser.title')}`"
                   :body="`${$t('setting.accessGroup.confirmations.deleteUser.body', {name: selectedUser.name})}`"
                   @accept="deleteUser"/>
  </div>
</template>

<script>
import {getUsers} from '../../../../../http/requests/users/users'
import CustomIcon from '../../../../../components/customIcon/customIcon'
import CustomDialog from "../../../../../components/customDialog/customDialog.vue";
import {deleteUserFromRole} from "../../../../../http/requests/roles";
import axios from "axios";

export default {
  name: 'accessGroupUsers',
  components: {
    CustomDialog,
    CustomIcon,
    UserProfile: () => import('../../../users/user/profile/userProfile')
  },
  props: {
    roleId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      showUserPromptStatus: false,
      loadingTimer: 0,
      options: {
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'deleteAction',
          i18n: 'users.list.table.header.deleteAction',
          color: 'danger',
          width: '70px',
          minWidth: 70,
          align: 'center',
          locked: true,
          fixed: true,
          actions: true,
          showAction: 'always',
          event: 'user:deleted',
          action: {
            icon: 'TRASH',
            iconPack: 'useral',
            color: 'danger',
            type: 'button'
          },
          classes: 'mx-auto'
        },
        {
          field: 'maxDebt',
          i18n: 'users.list.table.header.maxDebt',
          hideCurrency: true,
          align: 'right',
          width: 'calc((100% / 10))',
          minWidth: 130,
          sortable: true,
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 3
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 4
            }
          ]
        },
        {
          field: 'balance',
          i18n: 'users.list.table.header.balance',
          hideCurrency: true,
          align: 'right',
          width: 'calc((100% / 10))',
          minWidth: 130,
          sortable: true,
          filter: true,
          filterTypes: [
            {
              icon: 'equals',
              name: 'برابر باشد',
              i18n: 'draggableTable.filter.types.equals',
              id: 1
            },
            {
              icon: 'not-equal',
              name: 'برابر نباشد',
              i18n: 'draggableTable.filter.types.notEqual',
              id: 2
            },
            {
              icon: 'greater-than-equal',
              name: 'بزرگتر مساوی',
              i18n:
                'draggableTable.filter.types.greaterThanEquals',
              id: 4
            },
            {
              icon: 'less-than-equal',
              name: 'کوچکتر مساوی',
              i18n: 'draggableTable.filter.types.lessThanEquals',
              id: 3
            }
          ],
          footer: {
            // type: 'auto-sum',
            // textType: 'price'
          }
        },
        {
          field: 'phoneNumber',
          i18n: 'users.list.table.header.phoneNumber',
          width: 'calc((100% / 10))',
          minWidth: 130,
          align: 'center',
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'company',
          i18n: 'users.list.table.header.company',
          width: 'calc((100% / 10))',
          minWidth: 100,
          sortable: true,
          // locked: true,
          align: 'center',
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'name',
          i18n: 'users.list.table.header.name',
          align: 'left',
          ellipsis: true,
          width: 'calc((100% / 10) * 2)',
          minWidth: 250,
          sortable: true,
          // locked: true,
          filter: true,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'registryDate',
          i18n: 'users.list.table.header.registryDate',
          width: 'calc((100% / 10))',
          minWidth: 150,
          align: 'center',
          sortable: true,
          filter: true,
          filterType: 'date',
          filterRange: true,
          filterTypes: [
            {
              icon: 'calendar',
              name: 'تقویم',
              i18n: '',
              id: 1
            }
          ]
        },
        {
          field: 'row',
          i18n: 'users.list.table.header.row',
          width: '50px',
          minWidth: 50,
          align: 'center',
          fixed: true,
          locked: true,
          // sortable: true,
          footer: {
            /*type: 'auto-counter'*/
          }
        }
      ],
      data: [],
      filters: {roleId: `${this.roleId}`},
      sorts: ['order[0]=created_at,desc'],
      selectedUser: {},
      page: 1,
      endedList: false
    }
  },
  created () {
    this.getUsers()
  },
  methods: {
    getUsers () {
      if (!this.endedList) {
        if (this.$refs.accessGroupUsersListTable && this.data.length === 0) {
          this.$refs.accessGroupUsersListTable.loadMoreStatus = 'FirstLoad'
        } else if (this.$refs.accessGroupUsersListTable && this.data.length > 0) {
          this.$refs.accessGroupUsersListTable.loadMoreStatus = 'Loading'
        }

        getUsers(this.page, this.filters, this.sorts).then((res) => {
          this.endedList = res.data.data.length === 0

          res.data.data.forEach((user) => {
            this.data.push({
              row: this.data.length + 1,
              id: user.id,
              registryDate: this.$u.convertUtcToLocale(user.created_at, this.$u.locale.validator('moment.date')),
              phoneNumber: {
                value: user.phone_number,
                classes: this.$vs.rtl ? 'rtl' : 'ltr'
              },
              name: `${user.name} ${user.family}`,
              company: user.company ? user.company : '',
              balance: {
                type: 'price',
                value: user.balance ? `${Math.abs(user.balance)}` : 0,
                classes: user.balance > 0 ? 'useral-text-danger' : 'useral-text-success'
              },
              maxDebt: {
                type: 'price',
                value: user.max_debt ? user.max_debt : 0
              }
            })
          })

          this.page = res.data.pagination.current_page + 1
          if (res.data.pagination.current_page === 1) {
            const row_index = this.columnsLabel.map((e) => {
              return e.field
            }).indexOf('row')
            this.columnsLabel[row_index].footer.value = res.data.pagination.total
          }

          if (this.$refs.accessGroupUsersListTable) this.$refs.accessGroupUsersListTable.loadMoreStatus = ''
        })
          .catch((error) => {
            console.log(error)
            if (this.$refs.accessGroupUsersListTable && !axios.isCancel(error)) this.$refs.accessGroupUsersListTable.loadMoreStatus = 'Danger'
          })
      }
    },
    showUser (user) {
      this.selectedUser = user
      this.showUserPromptStatus = true
    },
    setFilters (filters) {
      const filters_list = {roleId: `${this.roleId}`}
      Object.keys(filters).forEach((key) => {
        switch (key) {

        case 'maxDebt':
          if (filters[key].search !== '') filters_list.max_debt = `${  filters[key].search  },${  filters[key].type.id}`
          break

        case 'balance':
          if (filters[key].search !== '') filters_list.balance = `${  this.$u.parseFloat(filters[key].search) * -1  },${  filters[key].type.id}`
          break

        case 'phoneNumber':
          if (filters[key].search !== '') filters_list.phone_number = `${  filters[key].search}`
          break

        case 'company':
          if (filters[key].search !== '') filters_list.company = `${  filters[key].search}`
          break

        case 'name':
          if (filters[key].search !== '') filters_list.full_name = `${  filters[key].search}`
          break

        case 'registryDate':
          if (filters[key].search !== '') {
            let filter = ''
            if (Array.isArray(filters[key].search)) {
              filter = filters[key].search.map(elm => this.$u.convertLocaleToUtc(elm, this.$u.locale.validator('moment.date'))).join(this.$u.globalData.filterSeperator)
            } else {
              filter = this.$u.convertLocaleToUtc(filters[key].search, this.$u.locale.validator('moment.date'))
            }
            filters_list.created_at = `${filter}`
          }
          break
        }
      })

      this.data = []
      this.page = 1
      this.endedList = false
      this.filters = filters_list
      this.$nextTick(() => {
        this.getUsers()
      })
    },
    setSort (columns) {
      const sorts_list = []
      Object.keys(columns).forEach((key) => {
        switch (key) {

        case 'row':
          sorts_list.push(`order[0]=id,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'maxDebt':
          sorts_list.push(`order[0]=max_debt,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'balance':
          sorts_list.push(`order[0]=virtual_balance,${  columns[key] ? 'asc' : 'desc'}`)
          break

        case 'phoneNumber':
          sorts_list.push(`order[0]=phone_number,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'company':
          sorts_list.push(`order[0]=company,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'name':
          sorts_list.push(`order[0]=name,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'registryDate':
          sorts_list.push(`order[0]=created_at,${  columns[key] ? 'desc' : 'asc'}`)
          break
        }
      })

      if (!sorts_list.length) sorts_list.push('order[0]=created_at,desc')

      this.data = []
      this.page = 1
      this.endedList = false
      this.sorts = sorts_list
      this.$nextTick(() => {
        this.getUsers()
      })
    },
    deleteUser () {
      deleteUserFromRole(this.roleId, this.selectedUser.id).then(response => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('setting.accessGroup.notifications.deleteUser.success'),
          color: 'success',
          time: this.$u.getReadingTime(this.$t('setting.accessGroup.notifications.deleteUser.success')),
          icon: 'icon-check',
          iconPack: 'feather'
        })
        this.data = []
        this.page = 1
        this.endedList = false
        this.$nextTick(() => {
          this.getUsers()
        })
      }).catch(error => {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('setting.accessGroup.notifications.deleteUser.error'),
          color: 'danger',
          time: this.$u.getReadingTime(this.$t('setting.accessGroup.notifications.deleteUser.error')),
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
      })
    }
  }
}
</script>

<style scoped>
.access-group-users {
  height: 100%;
}
</style>
