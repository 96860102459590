/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/store'
import {afterEach} from '@/router/afterEach'
import {beforeEach} from '@/router/beforeEach'

import accountancy from './routes/accountancy'
import companies from './routes/companies'
// import events from './routes/events'
import home from './routes/home'
import products from './routes/products'
import profile from './routes/profile'
import purchases from './routes/purchases'
import reports from './routes/reports'
import sales from './routes/sales'
import services from './routes/services'
import settings from './routes/settings'
import warehouses from './routes/warehouses'
// import ticketing from './routes/ticketing'
import treasury from './routes/treasury'
import users from './routes/users'
import characters from './routes/characters'
import wooreceiver from './routes/wooreceiver'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return {
      x: 0,
      y: 0
    }
  },
  routes: [

    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('../layouts/main/Main.vue'),
      meta: {requiresAuth: true},
      children: [
        accountancy,
        companies,
        // events,
        home,
        products,
        profile,
        purchases,
        reports,
        sales,
        services,
        settings,
        warehouses,
        // ticketing,
        treasury,
        users,
        characters,
        wooreceiver
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/login',
          name: 'page-login',
          component: () => import(/* webpackChunkName: "page-login" */ '@/views/pages/Login.vue')
        },
        {
          path: '/error-404',
          name: 'page-error-404',
          component: () => import(/* webpackChunkName: "page-error-404" */ '@/views/pages/Error404.vue')
        },
        {
          path: '/sales/invoices/:id/print',
          name: 'print-sale-invoice-page',
          component: () => import(/* webpackChunkName: "" */ '@/views/admin/sales/invoices/print/printInvoice.vue'),
          meta: {
            noHideLoading: true
          }
        },
        {
          path: '/sales/return-invoices/:id/print',
          name: 'print-return-sale-invoice-page',
          component: () => import(/* webpackChunkName: "" */ '@/views/admin/sales/returnInvoices/print/printInvoice.vue')
        },
        {
          path: '/purchases/invoices/:id/print',
          name: 'print-purchase-invoice-page',
          component: () => import(/* webpackChunkName: "" */ '@/views/admin/purchases/invoices/print/printInvoice.vue')
        },
        {
          path: '/purchases/return-invoices/:id/print',
          name: 'print-return-purchase-invoice-page',
          component: () => import(/* webpackChunkName: "" */ '@/views/admin/purchases/returnPurchase/print/printInvoice.vue')
        },
        {
          path: '/warehouses/receipts/:id/print',
          name: 'print-stores-receipt-page',
          component: () => import(/* webpackChunkName: "print-stores-receipt-page" */ '@/views/admin/warehouses/receipts/receipt/print/printWarehouseReceipt.vue')
        },
        {
          path: '/warehouses/receipts/:id/label',
          name: 'stores-receipt-label-page',
          component: () => import(/* webpackChunkName: "stores-receipt-label-page" */ '@/views/admin/warehouses/receipts/receipt/label/warehouseReceiptLabel.vue')
        },
        {
          path: '/treasury/payment/:id/print',
          name: 'print-payment-page',
          component: () => import(/* webpackChunkName: "print-payment-page" */ '@/views/admin/accountancy/documents/print/PrintPayment.vue')
        },
        {
          path: '/treasury/receive/:id/print',
          name: 'print-receive-page',
          component: () => import(/* webpackChunkName: "print-receive-page" */ '@/views/admin/accountancy/documents/print/PrintReceive.vue')
        },
        {
          path: '/treasury/internal-funds-transfers/:id/print',
          name: 'print-internal-funds-transfer-page',
          component: () => import(/* webpackChunkName: "print-internal-funds-transfer-page" */ '@/views/admin/accountancy/documents/print/PrintInternalFundsTransfer.vue')
        },
        {
          path: '/treasury/cost/:id/print',
          name: 'print-cost-page',
          component: () => import(/* webpackChunkName: "print-cost-page" */ '@/views/admin/accountancy/documents/print/PrintCost.vue')
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/error-404'
    }
  ]
})

router.afterEach(afterEach)

export default router

router.beforeEach(beforeEach)
