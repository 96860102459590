<template>
  <div class="insert-attribute-value-box">
    <vs-row>
      <custom-validate-input class="w-full"
                             v-model="attributeValue.value"
                             :in-valid="$v.attributeValue.value.$invalid"
                             :autocomplete="false"
                             :name="Math.random()"
                             :label="$t('attributes.labels.value')"
                             @input="handleNameChange"
                             @pressEnter="sendData"/>

      <custom-validate-input class="w-full"
                             v-model="attributeValue.slug"
                             :in-valid="$v.attributeValue.slug.$invalid"
                             :autocomplete="false"
                             :name="Math.random()"
                             :label="$t('attributes.labels.slug')"
                             @pressEnter="sendData"/>
<!--      <vs-input class="attribute-value w-full"
                :label="$t('attributes.labels.value')"
                :danger="attributeValue.value.length > 30"
                :danger-text="attributeValue.value.length > 30 ? $t('attributes.validators.value', {num: 30}) : ''"
                v-model="attributeValue.value"
                :autocomplete="false"
                :name="Math.random()"
                @input="handleNameChange"/>
      <vs-input class="attribute-value w-full"
                :label="$t('attributes.labels.slug')"
                :danger="attributeValue.slug.length > 30"
                :danger-text="attributeValue.slug.length > 30 ? $t('attributes.validators.valueSlug', {num: 30}) : ''"
                :autocomplete="false"
                :name="Math.random()"
                v-model="attributeValue.slug"/>-->
    </vs-row>

    <button v-show="false"
            id="saveBTN"
            @click="sendData()"/>
  </div>
</template>

<script>
import CustomValidateInput from "../../../../../components/customInput/customValidateInput.vue";
import {maxLength, required} from "vuelidate/lib/validators";

export default {
  name: 'updateAttributeValue',
  components: {CustomValidateInput},
  props: {
    attributeId: {
      type: Number,
      default: 0
    },
    value: {
      type: Object,
      default: () => { return {} }
    },
    shops: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  validations: {
    attributeValue: {
      value: {
        value: {
          required,
          maxLength: maxLength(30)
        }
      },
      slug: {
        value: {
          required,
          maxLength: maxLength(30)
        }
      }
    }
  },
  created () {
    this.attributeValue = {
      id: this.value.id,
      value: {
        value: this.value.value || '',
        isValid: true
      },
      slug: {
        value: this.value.slug || '',
        isValid: true
      }
    }
  },
  data () {
    return {
      attributeValue: {
        value: {
          value: '',
          isValid: true
        },
        slug: {
          value: '',
          isValid: true
        }
      }
    }
  },
  methods: {
    handleNameChange () {
      this.attributeValue.slug.value = this.attributeValue.value.value.toLowerCase().replaceAll(' ', '-')
    },
    sendData () {
      if (!this.$v.attributeValue.value.value.maxLength) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('attributes.validators.value', {num: 30}),
          time: this.$u.getReadingTime(this.$t('attributes.validators.value', {num: 30})),
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }
      if (!this.$v.attributeValue.slug.value.maxLength) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('attributes.validators.slug', {num: 30}),
          time: this.$u.getReadingTime(this.$t('attributes.validators.slug', {num: 30})),
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }
      if (!this.$v.attributeValue.value.value.required) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('attributes.validators.valueRequired'),
          time: this.$u.getReadingTime(this.$t('attributes.validators.valueRequired')),
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }
      if (!this.$v.attributeValue.slug.value.required) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('attributes.validators.slugRequired'),
          time: this.$u.getReadingTime(this.$t('attributes.validators.slugRequired')),
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
        return false
      }

      this.$emit('updated', this.attributeValue)
    }
  }
}
</script>

<style scoped>

</style>
