import { render, staticRenderFns } from "./insertAccessGroup.vue?vue&type=template&id=68877e34&scoped=true&"
import script from "./insertAccessGroup.vue?vue&type=script&lang=js&"
export * from "./insertAccessGroup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68877e34",
  null
  
)

export default component.exports