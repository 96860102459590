<template>
  <vx-card class="access-groups-list"
           :class="[{'main-box top-zero-radius mx-auto p-2': $route.name === 'accessGroupsList', 'no-box-shadow': $route.name !== 'accessGroupsList'}]">

    <transition name="fade" mode="out-in">
      <custom-skeleton
        v-if="loading"
        key="loading"
        type="tree"
        :lines="6"
      />
      <div key="access" v-else>
      <custom-tree :root="true"
                   :config="treeConfig"
                   :node="tree.node"
                   :children="tree.children"
                   :actions="tree.actions"
                   v-model="selectedItems"
                   @node:select="handleInputAccessGroup"
                   @node:unselect="handleInputAccessGroup"
                   @insert="handleOpenInsertAccessGroupPrompt"
                   @update="handleOpenEditAccessGroupPrompt"
                   @delete="handleOpenDeleteAccessGroupPrompt"
                   @users="handleShowAccessGroupUsersPrompt"/>
    </div>
    </transition>

    <!-- insert access group prompt -->
    <vs-prompt
      class="p-0"
      color="danger"
      :buttons-hidden="true"
      title=""
      :active.sync="insertAccessGroupPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="$u.click('saveBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('setting.accessGroup.insert.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="insertAccessGroupPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <insert-access-group :parent-id="selectedItem.id"
                             :available-permissions="selectedItem && selectedItem.permissions ? selectedItem.permissions.map((p) => { return p.id }) : []"
                             @insert="insertAccessGroupPromptStatus = false"/>
      </div>
    </vs-prompt>

    <!-- edit access group prompt -->
    <vs-prompt
      class="p-0"
      color="danger"
      :buttons-hidden="true"
      title=""
      :active.sync="editAccessGroupPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="$u.click('saveBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('setting.accessGroup.insert.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="editAccessGroupPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <edit-access-group :access-group="selectedItem"
                           @edit="editAccessGroupPromptStatus = false"/>
      </div>
    </vs-prompt>

    <!-- show access group users prompt -->
    <vs-prompt
      class="big-prompt p-0"
      color="danger"
      :buttons-hidden="true"
      title=""
      :active.sync="showAccessGroupUsersPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div v-if="$u.checkPermissions('user.set_role')"
                 @click="insertUserToAccessGroupPromptStatus = true">
              <custom-icon icon="PLUS" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ selectedItem.label }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showAccessGroupUsersPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <access-group-users :role-id="selectedItem.id"/>
      </div>
    </vs-prompt>

    <!-- insert user to access group prompt -->
    <vs-prompt
      class="p-0"
      color="danger"
      :buttons-hidden="true"
      title=""
      :active.sync="insertUserToAccessGroupPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div v-if="$u.checkPermissions('user.set_role')"
                 @click="$u.click('saveBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ selectedItem.label }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="insertUserToAccessGroupPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <insert-user-to-access-group :role-id="selectedItem.id"
                                     @insert="insertUserToAccessGroupPromptStatus = false, showAccessGroupUsersPromptStatus = false"/>
      </div>
    </vs-prompt>

    <!-- delete access confirmation -->
    <custom-dialog ref="deleteAccessGroupConfirmation"
                   :title="$t('setting.accessGroup.confirmations.delete.title')"
                   :body="$t('setting.accessGroup.confirmations.delete.body', {name: selectedItem.label})"
                   @accept="deleteAccessGroup"/>
  </vx-card>
</template>

<script>
import {getRoles} from '@/http/requests/roles'
import CustomTree from '../../../../../components/Tree/CustomTree'
import InsertAccessGroup from '../insert/insertAccessGroup'
import CustomIcon from '../../../../../components/customIcon/customIcon'
import CustomDialog from '../../../../../components/customDialog/customDialog'
import {deleteRole} from '../../../../../http/requests/roles'
import EditAccessGroup from '../edit/editAccessGroup'
import AccessGroupUsers from '../usersList/accessGroupUsers'
import InsertUserToAccessGroup from "../usersList/insertUserToAccessGroup";
import CustomSkeleton from "@/components/customSkeleton/index.vue";
import {mapGetters} from "vuex";

export default {
  name: 'accessGroupsList',
  metaInfo () {
    return {
      title: this.$t('setting.accessGroup.title')
    }
  },
  components: {
    CustomSkeleton,
    InsertUserToAccessGroup,
    AccessGroupUsers, EditAccessGroup, CustomDialog, CustomIcon, InsertAccessGroup, CustomTree},
  model: {
    prop: 'defaultSelectedItems',
    event: 'input'
  },
  props: {
    selectable: {
      type: Boolean,
      default: false
    },
    defaultSelectedItems: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      loading: true,
      tree: {},
      treeConfig: {
        actions: [],
        collapse: true
      },
      selectedItem: {},
      selectedItems: [],
      insertAccessGroupPromptStatus: false,
      editAccessGroupPromptStatus: false,
      showAccessGroupUsersPromptStatus: false,
      insertUserToAccessGroupPromptStatus: false,
      actions: [
        {
          leftToolbar: [
            {
              id: 'routeBackBTN',
              route: {name: 'Settings'},
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'isMobile'
    }),
  },
  created () {
    if (!this.selectable) {
      if (this.$route.name === 'Settings' || !this.isMobile) {
        this.actions[0].leftToolbar.splice(0, 1)
      }
      this.$nextTick(() => {
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'mx-auto')
      })
      this.$store.dispatch('setPageTitle', this.$t('setting.accessGroup.title'))
    } else {
      this.treeConfig.selectable = true
      this.treeConfig.parentSelectable = true
      this.treeConfig.multiSelect = false
    }

    /* check user permission */
    if (this.$u.checkPermissions('user.show')) {
      this.treeConfig.actions.push({
        key: 'users',
        icon: 'USERS',
        color: 'primary',
        event: 'users'
      })
    }
    if (this.$u.checkPermissions('role.create')) {
      this.treeConfig.actions.push({
        key: 'insert',
        icon: 'PLUS',
        color: 'success',
        event: 'insert'
      })
    }
    if (this.$u.checkPermissions('role.update')) {
      this.treeConfig.actions.push({
        key: 'update',
        icon: 'EDIT',
        color: 'warning',
        event: 'update'
      })
    }
    if (this.$u.checkPermissions('role.delete')) {
      this.treeConfig.actions.push({
        key: 'delete',
        icon: 'TRASH',
        color: 'danger',
        event: 'delete'
      })
    }

    this.getRolesList()
  },
  methods: {
    getRolesList () {
      setTimeout(() => {
        getRoles().then(response => {
          this.tree = this.createTree(response.data.data, true)

          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      }, 500)
    },
    createTree (role, first = false) {
      const children = []
      if (role.children && role.children.length > 0) {
        role.children.forEach((child) => {
          children.push(this.createTree(child))
        })
      }

      let node = {
        id: role.id,
        label: role.name,
        allowedActiveSessions: role.allowed_active_sessions,
        parentId: role.parent_id,
        permissions: role.permissions
      }

      if (this.selectable) {
        node.show = this.defaultSelectedItems.includes(role.id)
        const selectedNodeIndex = this.selectedItems.map(elm => elm.id).indexOf(node.id)
        if (selectedNodeIndex > -1) {
          this.selectedItems[selectedNodeIndex] = node
        } else {
          this.selectedItems.push(node)
        }
      }

      const actions = {
        update: !first,
        delete: role.children.length === 0
      }

      return {
        node,
        children,
        actions
      }
    },
    handleInputAccessGroup () {
      const selectedItems = this.selectedItems.filter(elm => elm.show).map(elm => elm.id)
      console.log(selectedItems)
      this.$emit('input', selectedItems)
    },
    handleOpenInsertAccessGroupPrompt (accessGroup) {
      this.selectedItem = accessGroup
      this.insertAccessGroupPromptStatus = true
    },
    handleOpenEditAccessGroupPrompt (accessGroup) {
      this.selectedItem = accessGroup
      this.editAccessGroupPromptStatus = true
    },
    handleShowAccessGroupUsersPrompt (accessGroup) {
      this.selectedItem = accessGroup
      this.showAccessGroupUsersPromptStatus = true
    },
    handleOpenDeleteAccessGroupPrompt (accessGroup) {
      this.selectedItem = accessGroup
      this.$refs.deleteAccessGroupConfirmation.showDialog()
    },
    deleteAccessGroup () {
      deleteRole(this.selectedItem.id).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('setting.accessGroup.notifications.delete.success'),
          color: 'success',
          time: this.$u.getReadingTime(this.$t('setting.accessGroup.notifications.delete.success')),
          icon: 'icon-check',
          iconPack: 'feather'
        })

        this.$store.dispatch('helper/changeRole')
      }).catch(error => {
        switch (error.response.status) {
        case 409:
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('setting.accessGroup.notifications.delete.cantDelete'),
            color: 'danger',
            time: this.$u.getReadingTime(this.$t('setting.accessGroup.notifications.delete.cantDelete')),
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
          break

          case 403:
          case 423:
            break

        default:
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('setting.accessGroup.notifications.delete.error'),
            color: 'danger',
            time: this.$u.getReadingTime(this.$t('setting.accessGroup.notifications.delete.error')),
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
        }
      })
    }
  },
  watch: {
    '$store.state.helper.rolesChanged': {
      handler () {
        this.getRolesList()
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
