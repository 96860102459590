// =============================================================================
// TREASURY PAGES LAYOUTS
// =============================================================================

export default {
  path: '/treasury',
    component: () => import('../../../views/admin/treasury/index.vue'),
  children: [
  {
    path: '/',
    redirect: '/error-404'
  },
  {
    path: 'banks',
    component: () => import('../../../views/admin/treasury/banks/index.vue'),
    children: [
      {
        path: '/',
        name: 'treasuryBanks',
        component: () => import(/* webpackChunkName: "treasuryBanks" */ '../../../views/admin/treasury/banks/list/banksList.vue'),
        meta: {
          permission: 'bank.show',
          pageTitle: 'بانک ها',
          pageTitleI18n: 'treasury.banks.title'
          // rule: 'editor'
        }
      },
      {
        path: 'insert',
        name: 'insertTreasuryBank',
        component: () => import(/* webpackChunkName: "insertTreasuryBank" */ '../../../views/admin/treasury/banks/insert/insertBank.vue'),
        meta: {
          permission: 'bank.create',
          pageTitle: 'افزودن بانک',
          pageTitleI18n: 'treasury.banks.insert.title'
          // rule: 'editor'
        }
      },
      {
        path: 'trash',
        name: 'treasuryBankTrash',
        component: () => import(/* webpackChunkName: "treasuryBankTrash" */ '../../../views/admin/treasury/banks/trash/banksTrash.vue'),
        meta: {
          permission: 'bank.delete',
          pageTitle: 'سطل زباله بانک ها',
          pageTitleI18n: 'treasury.banks.trash.title'
          // rule: 'editor'
        }
      },
      {
        path: ':id',
        component: () => import('../../../views/admin/treasury/banks/bank/index.vue'),
        children: [
          {
            path: '/',
            name: 'treasuryBank',
            component: () => import(/* webpackChunkName: "treasuryBank" */ '../../../views/admin/treasury/banks/bank/menu/bankMenu.vue'),
            meta: {
              permission: 'bank.show',
              pageTitle: 'بانک',
              pageTitleI18n: 'treasury.banks.bank.title',
              layoutStatus: 'profile'
              // rule: 'editor'
            }
          },
          {
            path: 'edit',
            name: 'editTreasuryBank',
            component: () => import(/* webpackChunkName: "editTreasuryBank" */ '../../../views/admin/treasury/banks/bank/edit/editBank.vue'),
            meta: {
              permission: 'bank.update',
              pageTitle: 'ویرایش بانک',
              pageTitleI18n: 'treasury.banks.edit.title',
              layoutStatus: 'profile'
              // rule: 'editor'
            }
          },
          {
            path: 'transactions',
            name: 'transactionsTreasuryBank',
            component: () => import(/* webpackChunkName: "transactionsTreasuryBank" */ '../../../views/admin/treasury/banks/bank/transactions/bankTransactions.vue'),
            meta: {
              permission: 'bank.show',
              pageTitle: '',
              pageTitleI18n: 'treasury.banks.transactions.title',
              layoutStatus: 'profile'
              // rule: 'editor'
            }
          },
          {
            path: 'activities-log',
            name: 'BankActivitiesLog',
            component: () => import(/* webpackChunkName: "bankActivitiesLog" */ '../../../views/admin/treasury/banks/bank/activitiesLog/bankActivitiesLog.vue'),
            meta: {
              permission: 'activity_logs.show',
              pageTitle: 'لاگ های بانک',
              pageTitleI18n: 'treasury.banks.logs.title',
              layoutStatus: 'profile'
              // rule: 'editor'
            }
          }
        ]
      }
    ]
  },
  {
    path: 'payment-gateways',
    component: () => import('../../../views/admin/treasury/paymentGateways/index.vue'),
    children: [
      {
        path: '/',
        name: 'paymentGateways',
        component: () => import(/* webpackChunkName: "paymentGateways" */ '../../../views/admin/treasury/paymentGateways/list/paymentGateways.vue'),
        meta: {
          permission: 'payment_gateway.show',
          pageTitle: 'درگاه های پرداخت',
          pageTitleI18n: 'treasury.paymentGateways.title'
          // rule: 'editor'
        }
      },
      {
        path: 'insert',
        name: 'insertPaymentGateway',
        component: () => import(/* webpackChunkName: "insertPaymentGateway" */ '../../../views/admin/treasury/paymentGateways/insert/insertPaymentGateway.vue'),
        meta: {
          permission: 'payment_gateway.create',
          pageTitle: 'افزودن درگاه پرداخت',
          pageTitleI18n: 'treasury.paymentGateways.insert.title'
          // rule: 'editor'
        }
      },
      {
        path: 'trash',
        name: 'trashedPaymentGateways',
        component: () => import(/* webpackChunkName: "trashedPaymentGateways" */ '../../../views/admin/treasury/paymentGateways/trash/trashedPaymentGateways.vue'),
        meta: {
          permission: 'payment_gateway.delete',
          pageTitle: 'سطل زباله درگاه های پرداخت',
          pageTitleI18n: 'treasury.paymentGateways.trash.title'
          // rule: 'editor'
        }
      },
      {
        path: ':id',
        component: () => import('../../../views/admin/treasury/paymentGateways/paymentGateway/index.vue'),
        children: [
          {
            path: '/',
            name: 'paymentGateway',
            component: () => import(/* webpackChunkName: "paymentGateway" */ '../../../views/admin/treasury/paymentGateways/paymentGateway/profile/paymentGateway.vue'),
            meta: {
              permission: 'payment_gateway.show',
              pageTitle: 'درگاه پرداخت',
              pageTitleI18n: 'treasury.paymentGateways.paymentGateway.title',
              layoutStatus: 'profile'
              // rule: 'editor'
            }
          },
          {
            path: 'edit',
            name: 'editPaymentGateway',
            component: () => import(/* webpackChunkName: "editPaymentGateway" */ '../../../views/admin/treasury/paymentGateways/paymentGateway/edit/editPaymentGateway.vue'),
            meta: {
              permission: 'payment_gateway.update',
              pageTitle: 'ویرایش درگاه پرداخت',
              pageTitleI18n: 'treasury.paymentGateways.edit.title',
              layoutStatus: 'profile'
              // rule: 'editor'
            }
          },
          {
            path: 'transactions',
            name: 'transactionsPaymentGateway',
            component: () => import(/* webpackChunkName: "transactionsPaymentGateway" */ '../../../views/admin/treasury/paymentGateways/paymentGateway/transactions/paymentGatewayTransactions.vue'),
            meta: {
              permission: 'payment_gateway.show',
              pageTitle: 'گردش مالی درگاه پرداخت',
              pageTitleI18n: 'treasury.paymentGateways.transactions.title',
              layoutStatus: 'profile'
              // rule: 'editor'
            }
          },
          {
            path: 'receive',
            name: 'receivePaymentGateway',
            component: () => import(/* webpackChunkName: "transactionsPaymentGateway" */ '../../../views/admin/treasury/paymentGateways/paymentGateway/receive/receivedPaymentGatewayBalance.vue'),
            meta: {
              permission: 'payment_gateway.show',
              pageTitle: 'اعلام وصول درگاه پرداخت',
              pageTitleI18n: 'treasury.paymentGateways.receive.title',
              layoutStatus: 'profile'
              // rule: 'editor'
            }
          },
          {
            path: 'activities-log',
            name: 'activitiesLogPaymentGateway',
            component: () => import(/* webpackChunkName: "paymentGatewayActivitiesLog" */ '../../../views/admin/treasury/paymentGateways/paymentGateway/activitiesLog/paymentGatewayActivitiesLog.vue'),
            meta: {
              permission: 'activity_logs.show',
              pageTitle: 'لاگ های درگاه پرداخت',
              pageTitleI18n: 'treasury.paymentGateways.logs.title',
              layoutStatus: 'profile'
              // rule: 'editor'
            }
          }
        ]
      }
    ]
  },
  {
    path: 'cash_boxes',
    component: () => import('../../../views/admin/treasury/cashBoxes/index.vue'),
    children: [
      {
        path: '/',
        name: 'treasuryCashBoxes',
        component: () => import(/* webpackChunkName: "treasuryCashBoxes" */ '../../../views/admin/treasury/cashBoxes/list/cashBoxList.vue'),
        meta: {
          permission: 'cash.show',
          pageTitle: 'صندوق ها',
          pageTitleI18n: 'treasury.cashBoxes.title'
          // rule: 'editor'
        }
      },
      {
        path: 'insert',
        name: 'insertTreasuryCashBox',
        component: () => import(/* webpackChunkName: "insertTreasuryCashBox" */ '../../../views/admin/treasury/cashBoxes/insert/insertCashBox.vue'),
        meta: {
          permission: 'cash.create',
          pageTitle: 'افزودن صندوق',
          pageTitleI18n: 'treasury.cashBoxes.insert.title'
          // rule: 'editor'
        }
      },
      {
        path: 'trash',
        name: 'treasuryCashBoxTrash',
        component: () => import(/* webpackChunkName: "treasuryCashBoxTrash" */ '../../../views/admin/treasury/cashBoxes/trash/cashBoxesTrash.vue'),
        meta: {
          permission: 'cash.delete',
          pageTitle: 'سطل زباله صندوق ها',
          pageTitleI18n: 'treasury.cashBoxes.trash.title'
          // rule: 'editor'
        }
      },
      {
        path: ':id',
        component: () => import('../../../views/admin/treasury/cashBoxes/cashBox/index.vue'),
        children: [
          {
            path: '/',
            name: 'treasuryCashBox',
            component: () => import(/* webpackChunkName: "treasuryCashBox" */ '../../../views/admin/treasury/cashBoxes/cashBox/menu/cashBoxMenu.vue'),
            meta: {
              permission: 'cash.show',
              pageTitle: 'صندوق',
              pageTitleI18n: 'treasury.cashBoxes.cashBox.title',
              layoutStatus: 'profile'
              // rule: 'editor'
            }
          },
          {
            path: 'edit',
            name: 'editTreasuryCashBox',
            component: () => import(/* webpackChunkName: "editTreasuryCashBox" */ '../../../views/admin/treasury/cashBoxes/cashBox/edit/editCashBox.vue'),
            meta: {
              permission: 'cash.update',
              pageTitle: 'ویرایش صندوق',
              pageTitleI18n: 'treasury.cashBoxes.edit.title',
              layoutStatus: 'profile'
              // rule: 'editor'
            }
          },
          {
            path: 'transactions',
            name: 'transactionsTreasuryCashBox',
            component: () => import(/* webpackChunkName: "transactionsTreasuryCashBox" */ '../../../views/admin/treasury/cashBoxes/cashBox/transactions/cashBoxTransactions.vue'),
            meta: {
              permission: 'cash.show',
              pageTitle: 'گزارشات صندوق',
              pageTitleI18n: 'treasury.cashBoxes.transactions.title',
              layoutStatus: 'profile'
              // rule: 'editor'
            }
          },
          {
            path: 'activities-log',
            name: 'activitiesLogCashBox',
            component: () => import(/* webpackChunkName: "cashBoxActivitiesLog" */ '../../../views/admin/treasury/cashBoxes/cashBox/activitiesLog/cashBoxActivitiesLog.vue'),
            meta: {
              permission: 'activity_logs.show',
              pageTitle: 'لاگ های صندوق',
              pageTitleI18n: 'treasury.cashBoxes.logs.title',
              layoutStatus: 'profile'
              // rule: 'editor'
            }
          }
        ]
      }
    ]
  },
  {
    path: 'receive',
    component: () => import('../../../views/admin/treasury/receive/index.vue'),
    children: [
      {
        path: '',
        name: 'treasuryReceive',
        component: () => import(/* webpackChunkName: "treasuryReceive" */ '../../../views/admin/treasury/receive/list/receivesList.vue'),
        meta: {
          pageTitle: 'دریافت',
          pageTitleI18n: 'treasury.receive.list.title'
          // rule: 'editor'
        }
      },
      {
        path: 'trash',
        name: 'treasuryReceiveTrash',
        component: () => import(/* webpackChunkName: "treasuryReceiveTrash" */ '../../../views/admin/treasury/receive/trash/receivesTrash.vue'),
        meta: {
          pageTitle: 'دریافت',
          pageTitleI18n: 'treasury.receive.trash.title'
          // rule: 'editor'
        }
      },
      {
        path: 'insert',
        name: 'insertTreasuryReceive',
        component: () => import(/* webpackChunkName: "insertTreasuryReceive" */ '../../../views/admin/treasury/receive/insert/insertReceive.vue'),
        meta: {
          pageTitle: 'دریافت',
          pageTitleI18n: 'treasury.receive.insert.title'
          // rule: 'editor'
        }
      },
      {
        path: 'doc-:id',
        component: () => import(/* webpackChunkName: "treasuryReceive" */ '../../../views/admin/treasury/receive/index.vue'),
        children: [
          {
            path: '',
            name: 'treasuryReceiveDocument',
            component: () => import(/* webpackChunkName: "treasuryReceiveDocument" */ '../../../views/admin/treasury/receive/document/receiveDocument.vue'),
            meta: {
              pageTitle: 'سند دریافت',
              pageTitleI18n: 'treasury.receive.document.title'
              // rule: 'editor'
            }
          },
          {
            path: 'return',
            name: 'treasuryRefundReceiveDocument',
            component: () => import(/* webpackChunkName: "treasuryRefundReceiveDocument" */ '../../../views/admin/treasury/receive/return/insertReturnReceive.vue'),
            meta: {
              pageTitle: 'سند دریافت',
              pageTitleI18n: 'treasury.receive.return.title'
              // rule: 'editor'
            }
          },
          {
            path: 'activities-log',
            name: 'receiveActivitiesLog',
            component: () => import(/* webpackChunkName: "receiveActivitiesLog" */ '../../../views/admin/treasury/receive/activitiesLog/receiveActivitiesLog.vue'),
            meta: {
              permission: 'activity_logs.show',
              pageTitle: 'لاگ های دریافت',
              pageTitleI18n: 'treasury.receive.logs.title'
              // rule: 'editor'
            }
          },
        ]
      },
    ]
  },
  {
    path: 'cost',
    component: () => import('../../../views/admin/treasury/cost/index.vue'),
    children: [
      {
        path: '',
        name: 'treasuryCost',
        component: () => import(/* webpackChunkName: "treasuryCost" */ '../../../views/admin/treasury/cost/list/costsList.vue'),
        meta: {
          permission: 'cost.show',
          pageTitle: 'هزینه',
          pageTitleI18n: 'treasury.cost.list.title'
          // rule: 'editor'
        }
      },
      {
        path: 'trash',
        name: 'treasuryCostTrash',
        component: () => import(/* webpackChunkName: "treasuryCostTrash" */ '../../../views/admin/treasury/cost/trash/costsTrash.vue'),
        meta: {
          permission: 'cost.delete',
          pageTitle: 'هزینه',
          pageTitleI18n: 'treasury.cost.trash.title'
          // rule: 'editor'
        }
      },
      {
        path: 'insert',
        name: 'insertTreasuryCost',
        component: () => import(/* webpackChunkName: "insertTreasuryCost" */ '../../../views/admin/treasury/cost/insert/insertCost.vue'),
        meta: {
          permission: 'cost.create',
          pageTitle: 'هزینه جدید',
          pageTitleI18n: 'treasury.cost.insert.title'
          // rule: 'editor'
        }
      },
      {
        path: 'doc-:id',
        name: 'treasuryCostDocument',
        component: () => import(/* webpackChunkName: "treasuryCostDocument" */ '../../../views/admin/treasury/cost/document/costDocument.vue'),
        meta: {
          permission: 'cost.show',
          pageTitle: 'سند هزینه',
          pageTitleI18n: 'treasury.cost.document.title'
          // rule: 'editor'
        }
      },
      {
        path: 'doc-:id/activities-log',
        name: 'costActivitiesLog',
        component: () => import(/* webpackChunkName: "costActivitiesLog" */ '../../../views/admin/treasury/cost/activitiesLog/costActivitiesLog.vue'),
        meta: {
          permission: 'activity_logs.show',
          pageTitle: 'لاگ های هزینه',
          pageTitleI18n: 'treasury.cost.logs.title'
          // rule: 'editor'
        }
      },
    ]
  },
  {
    path: 'payment',
    component: () => import('../../../views/admin/treasury/payment/index.vue'),
    children: [
      {
        path: '',
        name: 'treasuryPayment',
        component: () => import(/* webpackChunkName: "treasuryPayment" */ '../../../views/admin/treasury/payment/list/paymentsList.vue'),
        meta: {
          pageTitle: 'پرداخت',
          pageTitleI18n: 'treasury.payment.list.title'
          // rule: 'editor'
        }
      },
      {
        path: 'trash',
        name: 'treasuryPaymentTrash',
        component: () => import(/* webpackChunkName: "treasuryPaymentTrash" */ '../../../views/admin/treasury/payment/trash/paymentsTrash.vue'),
        meta: {
          pageTitle: 'پرداخت',
          pageTitleI18n: 'treasury.payment.trash.title'
          // rule: 'editor'
        }
      },
      {
        path: 'insert',
        name: 'insertTreasuryPayment',
        component: () => import(/* webpackChunkName: "insertTreasuryPayment" */ '../../../views/admin/treasury/payment/insert/insertPayment.vue'),
        meta: {
          pageTitle: 'پرداخت جدید',
          pageTitleI18n: 'treasury.payment.insert.title'
          // rule: 'editor'
        }
      },
      {
        path: 'doc-:id',
        name: 'treasuryPaymentDocument',
        component: () => import(/* webpackChunkName: "treasuryPaymentDocument" */ '../../../views/admin/treasury/payment/document/paymentDocument.vue'),
        meta: {
          pageTitle: 'سند پرداخت',
          pageTitleI18n: 'treasury.payment.document.title'
          // rule: 'editor'
        }
      },
      {
        path: 'doc-:id/activities-log',
        name: 'paymentActivitiesLog',
        component: () => import(/* webpackChunkName: "paymentActivitiesLog" */ '../../../views/admin/treasury/payment/activitiesLog/paymentActivitiesLog.vue'),
        meta: {
          permission: 'activity_logs.show',
          pageTitle: 'لاگ های پرداخت',
          pageTitleI18n: 'treasury.payment.logs.title'
          // rule: 'editor'
        }
      },
    ]
  },
  {
    path: 'internal-funds-transfers',
    component: () => import('../../../views/admin/treasury/InternalFundsTransfer/index.vue'),
    children: [
      {
        path: '',
        name: 'treasuryInternalFundsTransfers',
        component: () => import(/* webpackChunkName: "treasuryInternalFundsTransfers" */ '../../../views/admin/treasury/InternalFundsTransfer/list/internalFundsTransfersList.vue'),
        meta: {
          pageTitle: 'انتقالی خزانه',
          pageTitleI18n: 'treasury.internalFundsTransfer.title'
          // rule: 'editor'
        }
      },
      {
        path: 'trash',
        name: 'treasuryInternalFundsTransfersTrash',
        component: () => import(/* webpackChunkName: "treasuryInternalFundsTransfersTrash" */ '../../../views/admin/treasury/InternalFundsTransfer/trash/internalFundsTransfersTrash.vue'),
        meta: {
          pageTitle: 'انتقالی خزانه',
          pageTitleI18n: 'treasury.internalFundsTransfer.trash.title'
          // rule: 'editor'
        }
      },
      {
        path: 'doc-:id',
        name: 'treasuryInternalFundsTransferDocument',
        component: () => import(/* webpackChunkName: "treasuryInternalFundsTransferDocument" */ '../../../views/admin/treasury/InternalFundsTransfer/document/internalFundsTransferDocument.vue'),
        meta: {
          pageTitle: 'انتقالی خزانه',
          pageTitleI18n: 'treasury.internalFundsTransfer.document.title'
          // rule: 'editor'
        }
      },
      {
        path: 'insert',
        name: 'insertTreasuryInternalFundsTransfers',
        component: () => import(/* webpackChunkName: "insertTreasuryInternalFundsTransfers" */ '../../../views/admin/treasury/InternalFundsTransfer/insert/insertInternalFundsTransfer.vue'),
        meta: {
          pageTitle: 'انتقالی خزانه جدید',
          pageTitleI18n: 'treasury.internalFundsTransfer.insert.title'
          // rule: 'editor'
        }
      },
      {
        path: 'doc-:id/activities-log',
        name: 'internalFundsTransfersActivitiesLog',
        component: () => import(/* webpackChunkName: "internalFundActivitiesLog" */ '../../../views/admin/treasury/InternalFundsTransfer/activitiesLog/internalFundActivitiesLog.vue'),
        meta: {
          permission: 'activity_logs.show',
          pageTitle: 'لاگ های انتقالی خرانه',
          pageTitleI18n: 'treasury.internalFundsTransfer.logs.title'
          // rule: 'editor'
        }
      },
    ]
  }
]
}
