<template>
  <div class="insert-user-page top-zero-radius p-2"
           :class="{'w-full': inModal, ' main-box md:w-2/3 lg:w-1/2': !inModal}">

    <div class="menu-side mb-0 sm:mb-0">
      <ul>
        <li class="menu-item"
            @click="tabIndex = 0">
          {{ $t('users.edit.dataCategory.generalInfo') }}
          <vs-icon :icon="$vs.rtl ? 'icon-chevron-left' : 'icon-chevron-right'"
                   icon-pack="feather"/>
        </li>

        <li class="menu-item"
            v-if="$u.checkPermissions('user.set_role')"
            @click="tabIndex = 1">
          {{ $t('users.edit.dataCategory.accessLevel') }}
          <vs-icon :icon="$vs.rtl ? 'icon-chevron-left' : 'icon-chevron-right'"
                   icon-pack="feather"/>
        </li>

        <!--        <li class="menu-item"
                    v-if="$u.checkPermissions('user.set_role') && userId !== $store.state.auth.user.id"
                    @click="tabIndex = 2">
                  {{ $t('users.edit.dataCategory.advanceInfo') }}
                  <vs-icon :icon="$vs.rtl ? 'icon-chevron-left' : 'icon-chevron-right'"
                           icon-pack="feather"/>
                </li>-->
      </ul>
    </div>

    <vs-divider class="md:hidden"/>

    <div class="view-side mt-3 sm:mt-0">
      <div v-show="tabIndex === 0"
           class="view-box">
        <vs-row>
          <vs-col>
            <div>
              <vs-row class="mb-1 justify-center">
                <custom-profile-image-input v-model="newUser.general.avatar" />
              </vs-row>
              <custom-validate-input class="w-full"
                                     :label="$t('users.insert.labels.name')"
                                     @keydown.enter="sendData"
                                     align="right"
                                     rtl
                                     :autocomplete="false"
                                     :name="Math.random()"
                                     :in-valid="$v.newUser.general.name.value.$invalid"
                                     :invalid-text="newUser.general.name.isInvalid"
                                     v-model="newUser.general.name"/>

              <custom-validate-input :label="$t('users.edit.labels.family')"
                                     class="w-full"
                                     @keydown.enter="sendData"
                                     align="right"
                                     rtl
                                     :autocomplete="false"
                                     :name="Math.random()"
                                     :in-valid="$v.newUser.general.lastName.value.$invalid"
                                     :invalid-text="newUser.general.lastName.isInvalid"
                                     v-model="newUser.general.lastName"/>

              <custom-validate-input :label="$t('users.edit.labels.company')"
                                     class="w-full"
                                     @keydown.enter="sendData"
                                     :autocomplete="false"
                                     align="right"
                                     rtl
                                     is-empty
                                     :name="Math.random()"
                                     :in-valid="$v.newUser.general.companyName.value.$invalid"
                                     :danger-text="newUser.general.companyName.isInvalid"
                                     v-model="newUser.general.companyName"/>

              <u-phone-input :label="$t('users.insert.labels.phoneNumber')"
                             class="w-full"
                             @keydown.enter="sendData"
                             :default-country-code="clubInfo.club_default_country_code"
                             v-model="newUser.general.phoneNumber"/>

              <custom-email-input :label="$t('users.edit.labels.email')"
                                  :classes="{'w-full': true}"
                                  @keydown.enter="sendData"
                                  :autocomplete="false"
                                  :name="Math.random()"
                                  is-empty
                                  :in-valid="$v.newUser.general.email.value.$invalid"
                                  :invalid-text="newUser.general.email.isInvalid"
                                  v-model="newUser.general.email"/>

              <custom-validate-textarea :label="`${$t('users.edit.labels.description')}`"
                                        :classes="{'w-full': true}"
                                        is-empty
                                        v-model="newUser.general.description"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div v-show="tabIndex === 1"
           class="view-box">
        <vs-row>
          <vs-col>
            <div v-if="$u.checkPermissions('user.set_role')"
                 class="insert-user-field">
              <access-groups-list :selectable="true" v-model="newUser.role.id" />
            </div>
          </vs-col>
        </vs-row>
      </div>
    </div>

    <vs-button id="insertUserBTN" class="useral-action-button" @click="sendData"/>
  </div>
</template>

<script>
import {maxLength, required, email} from 'vuelidate/lib/validators'
import {mapGetters} from 'vuex'
import CustomSelect from '@/components/customSelect/customSelect'
import InsertAddress from '@/components/insertAddress/insertAddress'
import AccordionList from '@/components/accordionList/AccordionList'
import {insertUser} from '@/http/requests/users/users'
import {getRoles} from '@/http/requests/roles'
import axios from "axios";
import CustomProfileImageInput from "../../../../components/customInput/customProfileImageInput";
import UPhoneInput from "../../../../components/customInput/UPhoneInput";
import CustomValidateInput from "../../../../components/customInput/customValidateInput.vue";
import CustomEmailInput from "../../../../components/customInput/customEmailInput.vue";
import CustomValidateTextarea from "@/components/customInput/customValidateTextarea.vue";
import AccessGroupsList from "@/views/admin/settings/access/list/accessGroupsList.vue";

export default {
  name: 'insertUser',
  metaInfo () {
    return {
      title: this.$t('users.insert.title')
    }
  },
  components: {
    AccessGroupsList,
    CustomValidateTextarea,
    CustomEmailInput,
    CustomValidateInput, UPhoneInput, CustomProfileImageInput, AccordionList, InsertAddress, CustomSelect},
  data () {
    return {
      tabIndex: 0,
      firstChange: true,
      userTypes: [
        {label: '-', value: '-1'},
        {label: this.$t('users.insert.gender.man'), value: '1'},
        {label: this.$t('users.insert.gender.woman'), value: '2'}
        /*{label: this.$t('users.types.company'), value: '3'}*/
      ],
      characters: [
        {
          name: this.$t('users.characters.shareholder'),
          characterId: 1
        },
        {
          name: this.$t('users.characters.employee'),
          characterId: 2
        },
        {
          name: this.$t('users.characters.intermediary'),
          characterId: 3
        },
        {
          name: this.$t('users.characters.supplier'),
          characterId: 4
        }
      ],
      accessList: [],
      selectedAccess: [],
      accessTreeOption: {
        type: 'accessList'
      },
      groupsList: [
        {
          label: this.$t('users.insert.base'),
          value: 0
        }
      ],
      newUser: {
        general: {
          avatar: {
            value: '',
            isValid: true
          },
          companyName: {
            isInvalid: '',
            value: ''
          },
          name: {
            isInvalid: '',
            value: ''
          },
          lastName: {
            isInvalid: '',
            value: ''
          },
          phoneNumber: {
            isValid: false,
            value: ''
          },
          email: {
            isInvalid: '',
            value: ''
          },
          description: {
            isInvalid: '',
            value: ''
          },
          type: {
            isInvalid: '',
            value: ''
          }
        },
        address: [],
        characters: [],
        financial: {
          maxDebt: ''
        },
        group: [],
        access: [],
        role: {
          id: []
        }
      },
      insertCharacterPromptStatus: false,
      editCharacterPromptStatus: false,
      new_address: {},
      insertAddressPromptStatus: false,
      editAddressPromptStatus: false,
      editAddressIndex: 0,
      insertGroupPromptStatus: false,
      newGroup: {
        label: '',
        value: 0
      },
      actions: [
        {
          toolbar: [
            {
              id: 'insertUserBTN',
              icon: 'icon-check',
              iconPack: 'feather',
              color: 'success'
            }
          ],
          leftToolbar: [
            {
              id: {name: 'users'},
              type: 'link',
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      ]
    }
  },
  props: {
    inModal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      clubInfo: 'setting/getClubInfo'
    })
  },
  validations: {
    newUser: {
      general: {
        name: {
          value: {
            required,
            maxLength: maxLength(30),
            regex (value) { return this.$u.validator('regex.user.name').test(value)}
          }
        },
        lastName: {
          value: {
            required,
            maxLength: maxLength(50),
            regex (value) { return this.$u.validator('regex.user.lastName').test(value)}
          }
        },
        phoneNumber: {
          value: ''
        },
        email: {
          value: {
            email,
            maxLength: maxLength(50)
          }
        },
        companyName: {
          value: {
            maxLength: maxLength(50)
          }
        }
      }
    }
  },
  created () {
    if (!this.inModal) {
      this.$nextTick(() => {
        this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'md:w-2/3 lg:w-1/2 mx-auto')
      })
    }
    this.newUser.general.type.value = this.userTypes[0]

    this.getAccessGroups()
  },
  methods: {
    removeOtherAccess (access) {
      for (let i = 0; i < this.newUser.access.length; i++) {
        if (this.newUser.access[i] !== access) {
          this.newUser.access.splice(i, 1)
        }
      }
    },
    removeOtherGroup (group) {
      for (let i = 0; i < this.newUser.group.length; i++) {
        if (this.newUser.group[i] !== group) {
          this.newUser.group.splice(i, 1)
        }
      }
    },
    companyNameValidator () {
      // this.newUser.general.companyName.isInvalid = this.newUser.general.companyName.value.length ? '' : 'نام شرکت نمی تواند خالی باشد'
      if (this.newUser.general.companyName.value === '') this.newUser.general.companyName.isInvalid = ''
      else if (this.newUser.general.companyName.value.length > 50) this.newUser.general.companyName.isInvalid = this.$t('users.insert.validators.company')
    },
    nameValidator () {
      this.newUser.general.name.isInvalid = this.$v.newUser.general.name.value.regex ? '' : this.$t('users.insert.validators.nameIsWrong')
      if (!this.$v.newUser.general.name.value.required) this.newUser.general.name.isInvalid = this.$t('users.insert.validators.nameCantEmpty')
      else if (!this.$v.newUser.general.name.value.maxLength) this.newUser.general.name.isInvalid = this.$t('users.insert.validators.name')
    },
    lastNameValidator () {
      this.newUser.general.lastName.isInvalid = this.$v.newUser.general.lastName.value.regex ? '' : this.$t('users.insert.validators.familyIsWrong')
      if (!this.$v.newUser.general.lastName.value.required) this.newUser.general.lastName.isInvalid = this.$t('users.insert.validators.familyCantEmpty')
      else if (!this.$v.newUser.general.lastName.value.maxLength) this.newUser.general.lastName.isInvalid = this.$t('users.insert.validators.family')
    },
    phoneNumberValidator () {
      // this.newUser.general.phoneNumber.isInvalid = this.$u.validator('regex.user.phoneNumber').test(this.newUser.general.phoneNumber.value) ? '' : this.$t('users.insert.validators.phoneNumber')
      // if (this.newUser.general.phoneNumber.value.length === 0) {
      //   this.newUser.general.phoneNumber.isInvalid = this.$t('users.insert.validators.phoneNumberCantEmpty')
      // }
    },
    emailValidator () {
      this.newUser.general.email.isInvalid = this.$v.newUser.general.name.value.email ? '' : this.$t('users.insert.validators.emailIsWrong')
      if (this.newUser.general.email.value === '') this.newUser.general.email.isInvalid = ''
      else if (!this.$v.newUser.general.email.value.maxLength) this.newUser.general.email.isInvalid = this.$t('users.insert.validators.email')
    },
    typeValidator () {
      this.newUser.general.type.value = this.newUser.general.type.value ? this.newUser.general.type.value : this.userTypes[0]
    },
    insertAddress () {
      document.getElementById('insertAddressBTN').click()

      if (Object.keys(this.new_address).length > 0) {
        this.newUser.address.push(this.new_address)
        this.new_address = {}
        this.insertAddressPromptStatus = false
      } else {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('users.insert.validators.requiredFieldIsEmpty'),
          time: this.$u.getReadingTime(this.$t('users.insert.validators.requiredFieldIsEmpty')),
          icon: 'icon-alert-circle',
          iconPack: 'feather',
          color: 'danger'
        })
      }
    },
    insertGroup () {
      this.newGroup.value = this.groupsList[this.groupsList.length - 1].value + 1
      this.groupsList.push({
        label: this.newGroup.label,
        value: this.newGroup.value
      })
      this.newGroup.label = ''

      this.insertGroupPromptStatus = false
    },
    editAddress () {
      document.getElementById('insertAddressBTN').click()

      if (Object.keys(this.new_address).length > 0) {
        this.newUser.address[this.editAddressIndex] = this.new_address
        this.new_address = {}
        this.insertAddressPromptStatus = false
      } else {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('users.insert.validators.requiredFieldIsEmpty'),
          time: this.$u.getReadingTime(this.$t('users.insert.validators.requiredFieldIsEmpty')),
          icon: 'icon-alert-circle',
          iconPack: 'feather',
          color: 'danger'
        })
      }
    },
    insertCharacter () {

    },
    editCharacter () {

    },
    checkMaxDebtDigit () {
      this.newUser.financial.maxDebt = this.$u.convertPersianNumberToEnglish(this.newUser.financial.maxDebt)
      this.newUser.financial.maxDebt = this.$u.priceFormat(this.newUser.financial.maxDebt)
    },
    checkPhoneNumberDigit () {
      // this.newUser.general.phoneNumber.value = this.$u.convertPersianNumberToEnglish(this.newUser.general.phoneNumber.value)
      //
      // if (this.newUser.general.phoneNumber.value.length > 11) {
      //   this.newUser.general.phoneNumber.value = this.newUser.general.phoneNumber.value.substring(0, 11)
      // }

      this.phoneNumberValidator()
    },
    getAccessGroups () {
      getRoles().then(response => {
        this.accessList = this.getRoles(response.data.data, true)
      })
    },
    getRoles (role, first = false) {
      const data = {
        parent: {
          id: role.id,
          title: role.name,
          parent_id: role.parent_id,
          permissions: role.permissions
        },
        children: [],
        options: {
          insertEnable: true,
          updateEnable: !first,
          deleteEnable: role.children.length === 0,
          selectable: !first
        }
      }

      if (role.children && role.children.length > 0) {
        role.children.forEach((child) => {
          data.children.push(this.getRoles(child))
        })
      }

      return data
    },
    sendData () {
      if (!this.$v.newUser.$invalid &&
        this.newUser.general.phoneNumber.isValid) {

        const user = {
          avatar: this.newUser.general.avatar.value || null,
          name: this.newUser.general.name.value,
          family: this.newUser.general.lastName.value,
          phone_number: this.newUser.general.phoneNumber.value,
          company: this.newUser.general.companyName.value.length ? this.newUser.general.companyName.value : null,
          max_debt: this.$u.parseFloat(this.newUser.financial.maxDebt.replaceAll(',', '')) ? this.$u.parseFloat(this.newUser.financial.maxDebt.replaceAll(',', '')) : 0
          /*role_id: null,*/
          /*group_id: null,*/
        }

        if (this.newUser.general.email.value) user.email = this.newUser.general.email.value

        if (this.newUser.general.description.value) user.description = this.newUser.general.description.value

        if (this.newUser.role) user.role_id = this.newUser.role.id[0] || null

        insertUser(user).then((response) => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('users.insert.notifications.insert.success'),
            icon: 'icon-check',
            iconPack: 'feather',
            time: this.$u.getReadingTime(this.$t('users.insert.notifications.insert.success')),
            color: 'success'
          })
          this.$emit('inserted', response.data.data)

          // this.$store.dispatch('removePageChanges')
          this.$store.dispatch('helper/changeUser')
          if (!this.inModal) this.$router.push({name: 'users'})
        }).catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.warning.title'),
              text: this.$t('alert.warning.repeatedRequestText'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: this.$u.getReadingTime(this.$t('alert.warning.repeatedRequestText')),
              color: 'warning'
            })
          } else {
            const errors = error.response.data.hasOwnProperty('errors') ? error.response.data.errors : {}
            if (Object.keys(errors).length) {
              if (Object.keys(errors).indexOf('phone_number') >= 0) {
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('users.insert.validators.duplicatePhoneNumber'),
                  time: this.$u.getReadingTime(this.$t('users.insert.validators.duplicatePhoneNumber')),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather'
                })
              }
            }
          }

        })

      }
    }
  },
  watch: {
    'newUser': {
      handler () {
        if (!this.firstChange) {
          // this.$store.dispatch('setPageChanges')
        }
        this.firstChange = false
      },
      deep: true
    },
    'newUser.general.type.value': {
      handler (val) {
        this.userTypes = [
          {label: this.$t('users.insert.gender.man'), value: '1'},
          {label: this.$t('users.insert.gender.woman'), value: '2'}
          // {label: this.$t('users.types.company'), value: '3'}
        ]

        for (let i = 0; i < this.userTypes.length; i++) {
          if (this.userTypes[i].label === val.label) {
            this.userTypes.splice(i, 1)
          }
        }
      },
      deep: true
    },
    'newUser.general.companyName.value': {
      handler () {
        this.companyNameValidator()
      },
      deep: true
    },
    'newUser.general.name.value': {
      handler () {
        this.nameValidator()
      },
      deep: true
    },
    'newUser.general.lastName.value': {
      handler () {
        this.lastNameValidator()
      },
      deep: true
    },
    'newUser.general.email.value': {
      handler () {
        this.emailValidator()
      },
      deep: true
    },
    'newUser.financial.maxDebt': {
      handler () {
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.vs-divider .vs-divider-border {
  border-color: #cecece !important;
}

.insert-user-page {
  width: 100%;
  height: calc(100% - 10px);
  display: flex;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  .menu-side {
    width: 200px;
    min-width: 200px;
    height: 100%;
    padding: 0 10px 0 0;

    @media (max-width: 767px) {
      width: 100%;
      min-width: 100%;
      padding: 0;
      height: fit-content;
    }

    ul {

      li {
        position: relative;
        border: 1px solid #cecece;
        border-radius: .5rem;
        margin-bottom: 10px;
        padding: 0 10px;
        line-height: 40px;
        cursor: pointer;
        transition: all 0.3s ease;

        .vs-icon {
          position: absolute;
          height: fit-content;
          top: 0;
          bottom: 0;
          right: 6px;
          margin: auto;
        }

        &:hover {
          transform: translateX(5px);
        }
      }
    }
  }

  .view-side {
    flex-grow: 1;
    height: 100%;
    border: 1px solid #cecece;
    padding: 5px;
    border-radius: .5rem;
    overflow-y: auto;


    @media (max-width: 767px) {
      width: 100%;
      min-width: 100%;
      height: calc(100% - 230px);
    }

    .view-box {
      width: 100%;
      height: 100%;
      padding: 5px;
      overflow: auto;

      &::-webkit-scrollbar {
        display: block;
        border-radius: var(--global-radius);
        width: 10px;
        background: #f9f9fd;
        right: 10px;
      }

      &::-webkit-scrollbar-thumb {
        display: block;
        border-radius: .2rem;
        background: #cecece;
      }
    }
  }

  .max-debt .vs-inputx {
    text-align: right !important;
  }

  .divider {
    margin: 20px 0;
    position: absolute;
    right: 0;
    left: 0;
    height: 1px;
    background: #cecece;
  }

  .fields-label {
    margin-top: 5px;
    font-size: 12px;
    font-weight: 500;

    .text-danger {
      font-weight: bold;
    }
  }

  .insert-user-tabs {
    margin-top: 40px;

    .address-row {
      position: relative;
      padding-left: 20px;
      line-height: 25px;
      color: #c2c6dc;

      &:first-child {
        padding: 0 !important;
        margin-bottom: 10px;
        color: #ffffff;
      }

      .edit-address-icon {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        height: fit-content;
        cursor: pointer;
      }

      .address-row-icon {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }
    }
  }

  .address-item {
    display: inline-block;
    margin: 10px;
    padding: 10px;
    background: #333333;
    border-radius: 10px;
    box-shadow: 0 0 10px -5px black;

    .edit-address-icon {
      background: #333333;
      padding: 3px;
    }
  }
}
</style>
